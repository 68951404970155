.number {
  font-size: 33px !important;
}
.skyBlue {
  color: #3a9af4 !important;
}
.ui.list .list > .item a.header,
.ui.list > .item a.header.blue {
  color: #8a82d0 !important;
}
.ui.list .list > .item a.header,
.ui.list > .item a.header.lightGreen {
  color: #2fc0a2 !important;
}
.ui.list .list > .item a.header,
.ui.list > .item a.header.skyBlue {
  cursor: pointer;
  color: #3a9af4 !important;
}
.b-right {
  border-right: 1px solid #dedede;
}
.statusNumber {
  font-size: 25px;
  font-family: "Roboto" !important;
  font-weight: 300 !important;
  line-height: 30px;
}
.statusLabel {
  font-size: 16px;
  font-family: "Roboto" !important;
  font-weight: 300 !important;
}
.tabMenu .secondary {
  margin-bottom: 0px !important;
}
.tabMenu .segment {
  margin-top: 0px !important;
}
/* .ui.segment {
  padding-top: 0px !important;
  border-top: 0px !important;
} */
.ui.secondary.pointing.menu {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
.ui.secondary.pointing.menu .active.item {
  border-color: #1b1c1d;
  color: rgba(0, 0, 0, 0.95);
  border-bottom: 4px solid var(--customPrimary) !important;
  font-weight: 500;
  font-family: "Roboto";
  font-size: 16px;
}
.ui.secondary.pointing.menu .item {
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto";
}
.cardBtn {
  border-radius: 15px !important;
  padding: 4px 12px !important;
  border: 0.5px solid #aba9a9 !important;
  font-size: 14px;
  box-shadow: unset !important;
}
.cardPad {
  padding-top: 1.5rem !important;
}
.listBadge {
  background: #feedf4 !important;
  padding: 10px !important;
  font-family: "Roboto";
}
.badgeNumber {
  font-size: 28px;
  font-weight: 500;
  color: #f64e91;
  font-family: "Roboto";
}
.badgeTxt {
  font-size: 15px;
  font-weight: 300;
  color: #4c4c4c;
  margin-left: 15px;
  font-family: "Roboto";
}
.statusCard {
  padding: 20px 25px;
}
.recentActivity {
  font-family: "Roboto";
  font-size: 14px;
  background: #feedff;
  color: #e31919;
  padding: 5px 15px !important;
}
.contractSection > .row > .column {
  padding-left: 0px !important;
}
