.highlightNumberFont {
  font-size: 30px;
  font-weight: 500;
}
.dataProductCol {
  color: #3c9af3;
}
.geographiesCol {
  color: #736cc9;
}
.clientsCol {
  color: #45c3a6;
}
.reviewsCol {
  color: #f55999;
}
.highlightText {
  font-size: 16pt;
  color: #212121;
}
.ui.segment.companyContactdiv {
  padding-top: 2rem !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.companyContactdiv {
  font-size: 16px !important;
  padding: 2rem !important;
  margin-top: 1rem !important;
  background-color: #ffffff !important;
  color: #4c4c4c;
}
