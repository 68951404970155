/* ---------------------- DMP CSS ------------------------- */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap"); */
:root {
  --customPrimaryLightest: hsl(212, 88%, 98%);
  --customPrimaryLighter: hsl(212, 88%, 95%);
  --customPrimaryLight: hsl(212, 96%, 74%);
  --customPrimary: hsl(212, 86%, 52%);
  --customPrimaryDark: hsl(212, 86%, 42%);
  --customPrimaryDarker: hsl(212, 82%, 34%);
  --customPrimaryDarkest: hsl(212, 80%, 24%);
  /* Header */
  --customHeader: hsl(218, 90%, 18%);
  --customHeaderDark: hsl(218, 50%, 24%);
  --customHeaderBorder: hsl(218, 42%, 40%);
  /* Global */
  --mainBg: hsl(210, 12%, 95%);
  --customModalDimmerBg: hsla(212, 12%, 42%, 92%);
  --customLink: var(--customPrimary);
  --customLinkHover: var(--customPrimaryDark);
  /* Colors */
  --customMoney: hsl(148, 82%, 40%);
  --customMoneyDark: hsl(148, 82%, 30%);
  --red: hsl(0, 71%, 51%);
  --black: hsl(0, 0%, 0%);
  --grey20: hsl(0, 0%, 20%);
  --grey40: hsl(0, 0%, 40%);
  --grey60: hsl(0, 0%, 60%);
  --grey80: hsl(0, 0%, 80%);
  --grey94: hsl(0, 0%, 94%);
  --borderRadSmall: 4px;
  font-family: "Roboto";
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: var(--mainBg);
  background: hsl(0, 0%, 90%);
}

::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 76%);
  border: 0px solid var(--mainBg);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 64%);
}

body {
  font-family: "Roboto" !important;
  line-height: 30px !important;
  font-size: 14px !important;
  font-weight: 400;
  background-color: var(--mainBg) !important;
  color: #374957 !important;
}
.customLink {
  color: var(--customLink) !important;
  cursor: pointer;
}

.customLink:hover {
  color: var(--customLinkHover) !important;
}
.cursorPointer {
  cursor: pointer !important;
}
.roboto-light {
  font-family: "Roboto";
  font-weight: 300;
}
.roboto-regular {
  font-family: "Roboto";
  font-weight: 400;
}
.roboto-medium {
  font-family: "Roboto";
  font-weight: 500;
}
.activeLInk {
  color: #6d60b0 !important;
  font: 15px;
  font-family: "Roboto";
  font-weight: 500;
}
.activeStatus {
  font-size: 15px;
  font-weight: 500;
  color: #f97500;
}
.dmpButton {
  padding: 15px 18px !important;
  border-radius: 25px !important;
  line-height: 1.2em !important;
  font-size: 15px !important;
  background: #5644b0 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 9px 0px 1px -1px rgba(249, 226, 5, 0.98) !important;
  box-shadow: 9px 0px 1px -1px rgba(249, 226, 5, 0.98) !important;
}
.dmpBrand {
  font-family: "Roboto" !important;
  font-size: 23px !important;
  font-weight: 400 !important;
  color: #829dc4 !important;
  padding-left: 0px !important;
  margin-left: 5px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  margin-right: 1em !important;
  padding-right: 10px !important;
  border-radius: 0px !important;
}
.brandBorder-r {
  border-right: 1px solid #4e4e4e !important;
}
.dmpBrand:hover {
  background: inherit !important;
}
.cardHeader {
  color: #2a2a2a !important;
  font-weight: 500 !important;
  font-size: 16px;
  font-family: "Roboto" !important;
}
.pageHeader {
  color: var(--customPrimaryDark) !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  font-family: "Roboto" !important;
  position: relative !important;
  top: 2px !important;
  line-height: normal;
}
/* ---------------------Background Colors------------------ */
.dmbBg {
  background: #f8f9fd !important;
}
.bgHeader {
  background-color: #f8f8f8 !important;
}
.bgWhite {
  background-color: #ffffff !important;
}
.bgShadow {
  -webkit-box-shadow: 0px 0px 3px 0.1px rgba(107, 107, 107, 0.38) !important;
  -moz-box-shadow: 0px 0px 3px 0.1px rgba(107, 107, 107, 0.38) !important;
  box-shadow: 0px 0px 3px 0.1px rgba(107, 107, 107, 0.38) !important;
}
.bgNav {
  padding: 4px 0 0 0 !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 2px -2px hsla(0, 0%, 0%, 0.4) !important;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.141) !important;
}
.bgNav .menu .item {
  color: var(--customPrimaryDarker) !important;
  font-size: 15px !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
}
.bgNav .menu:active {
  font-weight: 500;
}
.bgNav .dmpBrand {
  color: var(--customPrimaryDarker);
}
.bgCardBtn {
  background-color: #f8f8f8 !important;
}
.bgCategory {
  background-color: #fdf2f0 !important;
}
.bgSubCategory {
  background-color: #dffbfc !important;
}
.bgActivity {
  background-color: #feedf5;
}
.bgtc {
  background-color: #eeedf3 !important;
}
/* --------------- DMP Navigation Bar ------------------------- */
/* .menu .item {
  font-size: 15px;
} */
.impTextColor {
  color: #5645b0 !important;
}
#impTextColor {
  color: #5645b0 !important;
}
#loginItem {
  background: inherit !important;
  margin-left: 1em;
}
#loginItem:hover,
#loginItem:focus .active {
  background: inherit !important;
}
#loginBtn,
#loginBtn:hover,
#loginBtn:focus {
  color: #5645b0 !important;
  background: inherit !important;
  padding: 0px;
  margin: 0px;
}
.dmpNav {
  padding: 12px !important;
}
.dmpNavigation .menu .dropdown.ui.dmpDropdown,
.dmpNavigation.menu .dropdown.ui.dmpDropdown {
  padding: 10px 18px !important;
  border-radius: 5px;
  color: rgb(228, 228, 228) !important;
  border: 1px solid #3e4f77;
  background-color: #212f4c !important;
}
.dmpNavigation .navSearch .ui.input input {
  padding: 0.5em 1em;
  padding: 8.5px 1em;
  border-top-left-radius: 0px;
  border: 1px solid #3e4f77;
  border-left: 0px;
  border-top-right-radius: 5px;
  color: var(--customPrimaryDarker);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
  background-color: #212f4c !important;
}
.dmpNavigation .item.searchItem {
  padding: 0.6em 0em !important;
  margin-left: 0px !important;
}

.dmpNavigation .item .icon.navIcon {
  font-size: 16px !important;
  color: var(--customPrimaryDarker);
}
.dataProductDp {
  margin-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.dmpNav .menu .item {
  color: #333333 !important;
}
.dmpNavigation .menu .item {
  padding: 12px 8px !important;
}
.dmpNavigation .menu .active.item {
  /* border-bottom: 3px solid #6d60b0 !important; */
  border-radius: 0px !important;
  /* background: #1e2e53 !important; */
}
.dmpNavigation .menu .active.item.dropdown.dmpDropdown {
  border-bottom: 0px !important;
  border-radius: 0px !important;
}
.searchItem .ui.input {
  color: #ffffff !important;
}
.loginPopup {
  min-width: 295px !important;
}
.popupForm {
  padding: 23px 23px 0px 23px !important;
}
.loginPopup .field {
  margin-bottom: 25px !important;
}
.welcomeTxt {
  font-family: "Roboto" !important;
  font-weight: 300 !important;
  font-size: 20px;
  color: #333333 !important;
  padding-bottom: 23px;
}
.loginBtn {
  padding: 7px 25px !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  background: #5644b0 !important;
  color: #ffffff !important;
}

.btnSecondary {
  font-size: 15px !important;
  color: #5644b0 !important;
  border: 1px solid #5644b0 !important;
  border-radius: 20px !important;
  padding: 8px 15px !important;
}
.btnSecondaryDb1 {
  font-size: 15px !important;
  color: #fa7c01 !important;
  border: 1px solid #fa7c01b0 !important;
  border-radius: 20px !important;
  padding: 8px 15px !important;
}
.btnSecondaryDb2 {
  font-size: 15px !important;
  color: #50be07 !important;
  border: 1px solid #50be07 !important;
  border-radius: 20px !important;
  padding: 8px 15px !important;
}
.dmpBtn {
  padding: 8px 15px !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  background: #5644b0 !important;
  color: #ffffff !important;
}
.loadBtn {
  padding: 12px 35px !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  color: #5644b0 !important;
  background-color: #ffffff !important;
  border: 1px solid #5644b0 !important;
}
.addCustomBtn {
  border: 1px solid #5644b0 !important;
  padding: 7px 15px !important;
  border-radius: 5px !important;
  color: #584a96 !important;
  background-color: #ffffff !important;
}
.customBtn1Primary {
  border: 1px solid var(--customPrimary) !important;
  padding: 10px 10px !important;
  border-radius: var(--borderRadSmall) !important;
  color: #ffffff !important;
  background-color: var(--customPrimary) !important;
}
.customBtn1Secondary {
  border: 1px solid var(--customPrimary) !important;
  padding: 10px 10px !important;
  border-radius: var(--borderRadSmall) !important;
  color: var(--customPrimary) !important;
  background-color: #ffffff !important;
}
/* ---------------------- Input classes -------------------  */
.customInput input {
  border-radius: var(--borderRadSmall) !important;
}
.customInput input:focus {
  border: 1px solid var(--customPrimary) !important;
}
.customTextArea {
  border-radius: var(--borderRadSmall) !important;
}
.customTextArea:focus {
  border: 1px solid var(--customPrimary) !important;
}
/* -----------------------Background CSS----------------- */
.bgTransparent {
  background-color: hsla(0, 0%, 0%, 0) !important;
}

.bgPrimary {
  background-color: var(--customPrimary) !important;
}

.bgPrimaryLight {
  background-color: var(--customPrimaryLight) !important;
}

.bgPrimaryLighter {
  background-color: var(--customPrimaryLighter) !important;
}

.bgPrimaryLightest {
  background-color: var(--customPrimaryLightest) !important;
}

.bgPrimaryDark {
  background-color: var(--customPrimaryDark) !important;
}

.bgPrimaryDarker {
  background-color: var(--customPrimaryDarker) !important;
}

.bgPrimaryDarkest {
  background-color: var(--customPrimaryDarkest) !important;
}

.bgGrey20 {
  background-color: var(--grey20) !important;
}

.bgGrey40 {
  background-color: var(--grey40) !important;
}

.bgGrey60 {
  background-color: var(--grey60) !important;
}

.bgGrey80 {
  background-color: var(--grey80) !important;
}

.bgGrey94 {
  background-color: var(--grey94) !important;
}

/* -----------------------Color CSS----------------- */

.colPrimary {
  color: var(--customPrimary) !important;
}

.colPrimaryLight {
  color: var(--customPrimaryLight) !important;
}

.colPrimaryLighter {
  color: var(--customPrimaryLighter) !important;
}

.colPrimaryLightest {
  color: var(--customPrimaryLightest) !important;
}

.colPrimaryDark {
  color: var(--customPrimaryDark) !important;
}

.colPrimaryDarker {
  color: var(--customPrimaryDarker) !important;
}

.colPrimaryDarkest {
  color: var(--customPrimaryDarkest) !important;
}

.colMoney {
  color: var(--customMoney) !important;
}

.colWhite {
  color: #ffffff !important;
}

.colBlack {
  color: var(--black) !important;
}

.colGrey20 {
  color: var(--grey20) !important;
}

.colGrey40 {
  color: var(--grey40) !important;
}

.colGrey60 {
  color: var(--grey60) !important;
}

.colGrey80 {
  color: var(--grey80) !important;
}

/* -----------------------Text CSS----------------- */

p {
  font-size: 1em;
}

.breakLongText {
  word-break: break-all !important;
}

.textBold {
  font-weight: 500 !important;
  line-height: normal !important;
}

.textThin {
  font-weight: 200 !important;
  /* line-height: normal !important; */
}

.textSmaller {
  font-size: 0.82em !important;
  line-height: normal !important;
}

.textSmall {
  font-size: 0.92em !important;
  line-height: normal !important;
}

.textNormal {
  font-size: 1.08em !important;
  line-height: normal !important;
}

.textMedium {
  font-size: 1.34em !important;
}

.textBig {
  font-size: 1.6em !important;
}

.textBigger {
  font-size: 2.2em !important;
}

.textBiggest {
  font-size: 3.8em !important;
  line-height: 1.12em !important;
}

.contentLabel {
  font-size: 0.88em !important;
  line-height: normal !important;
  font-weight: 500;
  margin: 0;
}

.contentSmall {
  font-size: 0.92em !important;
  white-space: pre-wrap;
  line-height: 1;
  margin-bottom: 10px;
}

.contentNormal {
  font-size: 1em !important;
  white-space: pre-wrap;
  line-height: 1;
  margin-bottom: 10px;
}

.contentMedium {
  font-size: 1.26em !important;
  white-space: pre-wrap;
  line-height: 1.2;
  margin-bottom: 10px;
}

.contentBig {
  font-size: 1.4em !important;
  white-space: pre-wrap;
  line-height: 1.2;
  margin-bottom: 10px;
}

/* -----------------------Flex CSS----------------- */

.flex {
  display: flex !important;
}

.flexDirectionRow {
  flex-direction: row !important;
}

.flexDirectionColumn {
  flex-direction: column !important;
}

.flexJustifyEnd {
  justify-content: flex-end !important;
}

.flexJustifyCenter {
  justify-content: center !important;
}

.flexJustifySpaceBetween {
  justify-content: space-between !important;
}

.flexJustifySpaceAround {
  justify-content: space-around !important;
}

.flexAlignCenter {
  align-items: center;
}

/* -----------------------Error CSS----------------- */

.errorText {
  color: var(--red);
  font-size: 0.9em;
  padding-bottom: 4px;
  line-height: normal;
}

.validationInfoText {
  color: var(--grey60);
  font-size: 0.9em;
  padding-bottom: 4px;
  line-height: normal;
  font-style: italic;
}

.separator {
  color: var(--grey80);
  padding: 0 5px;
}

/* -----------------------Card CSS----------------- */

.fCard {
  cursor: pointer !important;
}

.fCard:hover {
  box-shadow: 0 6px 5px -5px rgb(58 62 66 / 25%) !important;
  border: var(--customPrimaryLight) 1px solid !important;
  transition: 0.1s ease-in;
  transform: translateY(-1px);
}

.fCard.active {
  border: var(--customPrimary) 1px solid !important;
  background: var(--customPrimaryLighter) !important;
}

.noShadow {
  box-shadow: none !important;
}

.stickToBottom {
  position: absolute;
  bottom: 14px;
  width: 100%;
}

/* -----------------------Dimensions CSS----------------- */
.min80vh {
  min-height: 80vh;
}
.min90vh {
  min-height: 90vh;
}
.min100vh {
  min-height: 100vh;
}
/* -----------------------Disabled Field CSS----------------- */

input[readOnly],
textarea[readOnly] {
  background: #f7f7f7 !important;
}

/* -----------------------Semantic CSS----------------- */
.ui.inverted.dimmer {
  background-color: var(--customModalDimmerBg) !important;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  border-color: var(--customPrimary) !important;
}

.ui.selection.dropdown:focus {
  border-color: var(--customPrimary) !important;
}

.ui.breadcrumb a {
  color: var(--customPrimary) !important;
}

.ui.steps .step.active .title {
  color: var(--customPrimary) !important;
}

.ui.form textarea:focus,
.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus,
.ui.input.focus > input,
.ui.input > input:focus {
  border-color: var(--customPrimary) !important;
}

.ui.form > .field {
  margin: 0 0 0.2em !important;
}

.ui.form > .fields {
  margin: 0 -0.5em 0.2em !important;
}

.ui.form .inline.fields {
  margin: 0 0 0.2em !important;
}

/* .ui.form .field > label {
  line-height: 1.28em;
} */

.ui.styled.accordion .title {
  padding: 0.24em 1em !important;
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
  color: rgba(0, 0, 0, 1) !important;
}

.tab-wrapped {
  overflow: auto;
  position: relative;
  min-height: 2.62em !important;
}

.overflow-auto {
  overflow: auto;
  position: relative;
}
.ui.segment {
  box-shadow: none !important;
  border-radius: 0.2rem !important;
}
/* .tabMenu .segment {
  padding: 6px 8px !important;
} */
.ui.tabular.menu .item {
  padding: 0.6em 1em !important;
}
.ui.tabular.menu .active.item {
  color: var(--customPrimary) !important;
}
/* -----------------------React Date Picker CSS----------------- */

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: var(--customPrimary) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background: var(--customPrimaryLight) !important;
}

.react-datepicker__triangle {
  left: 0px !important;
  transform: translate(110px, 0px) !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-dp-custom {
  width: 100% !important;
  padding-left: 32px !important;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: #4d4d4d !important;
  border-radius: 8px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

/* -----------------------Leaflet Map CSS----------------- */

.map-pin-default {
  filter: hue-rotate(233deg) brightness(01.2) !important;
}
.map-pin-active {
  filter: hue-rotate(136deg) brightness(01.2) !important;
}
.map-pin-inactive {
  filter: grayscale(1) brightness(1.2) !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px !important;
}

/* -----------------------React-PDF CSS----------------- */
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

/* -----------------------File Upload CSS----------------- */

.customFileInput {
  border: 1px dashed #ccc;
  display: inline-block;
  padding: 4px 12px;
  /* cursor: pointer; */
  width: 100%;
}
.customFileInput > .fileInput {
  display: none;
}

/* -----------------------AntD CSS----------------- */
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: var(--customPrimary) !important;
}
/* active pagination num */
.ant-pagination-item-active a {
  color: var(--customPrimary) !important;
}
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: var(--customPrimary) !important;
}
.ant-pagination-next:hover button {
  color: var(--customPrimary) !important;
}
.ant-pagination-item-active {
  border-color: var(--customPrimary) !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--customPrimary) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: var(--customPrimary) !important;
  box-shadow: 0 0 0 2px var(--customPrimaryLightest) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--customPrimaryLighter) !important;
}
.ant-table-pagination.ant-pagination {
  margin: 7px 6px !important;
}
.ant-table-thead th {
  background: #f8f8f8 !important;
}
.ant-btn-primary,
.ant-btn-link {
  color: #ffffff !important;
  background: var(--customPrimary) !important;
  border-color: var(--customPrimary) !important;
}
.ant-select-open .ant-select-selection {
  border-color: var(--customPrimary) !important;
  -webkit-box-shadow: var(--customPrimaryLight) !important;
  box-shadow: var(--customPrimaryLight) !important;
}
.ant-select-selection:hover {
  border-color: var(--customPrimary) !important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--customPrimaryLightest) !important;
}
.ant-table-filter-trigger.active,
.ant-table-filter-dropdown-link,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--customPrimary) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--customPrimary) !important;
  border-color: var(--customPrimary) !important;
}
.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: var(--customPrimaryLightest) !important;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: var(--customPrimary) !important;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: var(--customPrimary) !important;
  background-color: var(--customPrimaryLightest) !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--customPrimaryLightest) !important;
}
.ant-back-top {
  left: 12px !important;
  bottom: 12px !important;
  z-index: 10000 !important;
}

.ant-back-top-content {
  transform: scale(0.76) !important;
  opacity: 0.32 !important;
  color: #fff !important;
  background-color: var(--customPrimaryDarker) !important;
  border-radius: 20px !important;
}

.ant-back-top-content:hover {
  opacity: 0.62 !important;
}

.scrollableTable .ant-table-body {
  overflow-x: auto !important;
}
/* -----------------------Landing Page CSS----------------- */
.lpTitle {
  font-family: "Roboto" !important;
  font-weight: 300;
  font-size: 40px;
  color: #393939;
  line-height: 50px;
}
.lpText {
  font-family: "Roboto" !important;
  font-size: 15px;
  font-weight: 300;
  color: #313131;
  line-height: 22px;
}
.lpDivBody {
  padding: 0px 30px;
}
.platformImg {
  margin-top: -6rem;
}
.ui.card > .image {
  margin: auto;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.dataDiscoverySecton {
  background: #f8f8f8;
}
.dataDiscoverySecton .card {
  margin-right: 0px !important;
  width: 100% !important;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}
/* .ui.card > .content {
  text-align: center;
} */
.content > .description {
  line-height: 22px;
}
.sliderContainer {
  background: #45cbff !important;
  padding-top: 3rem !important;
  padding-bottom: 5rem !important;
}
.sliderContainer .card {
  width: 250px !important;
  border-radius: 8px !important;
  background-color: #e6f4f7 !important;
}
.innerSlider .slick-slider {
  padding-left: 32px;
}
.categoriesSlider button {
  margin-top: -5px;
}
.footerDMP {
  font-size: 40px !important;
  line-height: 5em !important;
  color: #2185d0 !important;
  vertical-align: middle;
}
.dmpFooter {
  background-color: #e4e4e4 !important;
}
.dmp .ui.segment {
  border: 0px !important;
}
.contractSection .ui.segment {
  padding-top: 1em !important;
  border: 0px !important;
}
.ui.segment.footer {
  background-color: #e4e4e4 !important;
  padding-top: 20px !important;
  padding-bottom: 16px !important;
}
.dmpFooter .list .item {
  font-size: 18px;
  font-family: "Roboto" !important;
  font-weight: 400;
  color: #6d6d6d !important;
  line-height: 2em !important;
}
.footerHeader {
  font-family: "Roboto" !important;
  font-size: 19px !important;
  color: #464545 !important;
  font-weight: 400;
  line-height: 2em !important;
}
.partnerText {
  font-size: 35px;
  color: #ffffff;
  font-weight: 300;
}
.noAc {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 25px;
  background: #e8e8e8;
  color: #393939;
}
/* ---------------------- Spacing classes -------------------  */

.w-100 {
  width: 100% !important;
}

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 1rem !important;
}

.m-5px {
  margin: 5px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.my-5px {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.mx-5px {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ml-5px {
  margin-left: 5px !important;
}

.mr-5px {
  margin-right: 5px !important;
}

#gridMar {
  margin-left: 1.6rem !important;
  margin-right: 1.6rem !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-n8 {
  margin-top: -8rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-0 {
  margin-right: 0px;
}

.mNeg-5px {
  margin-top: -5px;
}

.mNeg-2 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.p-0 {
  padding: 0px !important;
}

.p-5px {
  padding: 5px !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-5 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}

.pr-5px {
  padding-right: 5px !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pl-8px {
  padding-left: 8px !important;
}

.lh-25 {
  line-height: 25px !important;
}

.lh-22 {
  line-height: 22px !important;
}

/* ---------------------- Border classes -------------------  */

.border-y {
  border-top: 0.5px solid #d8d8d8 !important;
  border-bottom: 0.5px solid #d8d8d8 !important;
}
.border-0 {
  border: 0px !important;
}
.border-r {
  border-right: 1px solid #ebebeb;
}
.border-t {
  border-top: 1px solid #ebebeb;
}
.border-b {
  border-bottom: 1px solid #d8d8d8 !important;
}
.border-1 {
  border: 1px solid #ebebeb;
}
.border-dotted {
  border: 2px dashed #cccccc;
}
.border-rad {
  border-radius: 5px;
}
.boxShadow-0 {
  box-shadow: none !important;
}

.bgShadow-0 {
  box-shadow: none !important;
}
.v-align {
  vertical-align: middle !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.float-right {
  float: right;
}
.body {
  padding: 0px 20px;
}
.ui.menu {
  font-family: "Roboto" !important;
  font-weight: 400;
}
.ui.card .meta,
.ui.cards > .card .meta {
  font-size: 1em !important;
  color: rgba(82, 81, 81, 0.9) !important;
}
.ui.card > .content > .header:not(.ui),
.ui.cards > .card > .content > .header:not(.ui) {
  font-weight: 400 !important;
  color: #393939 !important;
}
.ui.mini.image {
  width: auto !important;
}
.ui.avatar.image {
  width: auto !important;
  height: auto !important;
}
.ui.list .list > .item,
.ui.list > .item,
ol.ui.list li,
ul.ui.list li {
  line-height: 30px !important;
}
.ui.list > .item.lh-25 {
  line-height: 25px !important;
}
/*--------------------- Display properties --------------*/
.d-flex {
  display: flex !important;
}
.d-inline {
  display: inline-block !important;
}
.d-inlineflex {
  display: inline-flex !important;
}

/* ----------------------Font sizes ------------------ */
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-26 {
  font-size: 26px !important;
}
.roboto-regular {
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}
.roboto-medium {
  font-weight: 500 !important;
  font-family: "Roboto" !important;
}
.roboto-light {
  font-weight: 300 !important;
  font-family: "Roboto" !important;
}
/* ---------------------- Text Color ------------------ */
.color-6c6c {
  color: #6c6c6d;
}
.color-5959 {
  color: #595959 !important;
}
.color-unit {
  color: #f87500;
}
.color-1616 {
  color: #161616;
}
.color-584 {
  color: #584a96 !important;
}
.color-2f26 {
  color: #2f2626 !important;
}
.color-3131 {
  color: #313131 !important;
}
.color-2525 {
  color: #252525 !important;
}
.color-377 {
  color: #3775d1 !important;
}
.color-7e7d7d {
  color: #7e7d7d !important;
}
.linkCol {
  color: #00baf0;
}
.color-4CDB {
  color: #4cdb45;
}
.color-E536 {
  color: #e53633;
}
.color-8181 {
  color: #818181;
}
.color-3535 {
  color: #353535;
}
.color-fff {
  color: #ffffff;
}
.categoryCardRow .content {
  min-height: 100px !important;
  text-align: center !important;
  line-height: 25px !important;
  font-size: 13px;
  color: #747475 !important;
}
.ui.progress .bar {
  background: #f87500 !important;
}
.ui.categoryTable.table .categoryBody tr td {
  padding: 5px 5px !important;
}
.ui.styled.contractSummary {
  box-shadow: unset !important;
}
.ui.contractSummary .title {
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 10px !important;
  color: #353535 !important;
  padding: 7px 15px !important;
  border-radius: 5px;
  background-color: #f7f7f7 !important;
}
.ui.contractSummary .active.title {
  margin-bottom: 0px !important;
  padding: 7px 15px !important;
  background-color: #f7f7f7 !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.ui.contractSummary .active.content {
  margin-bottom: 10px !important;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
.ui.contractSummary .content {
  margin-bottom: 0px !important;
}
.ui.styled.accordion.contractSummary > .title:first-child {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
}
.ui.selection.dropdown.priceNegoDropdown {
  min-width: 7em !important;
  min-height: 0.8em;
}
.tabsHeader {
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.partnerCards .ui.card {
  width: 300px !important;
  -webkit-box-shadow: 0px 0px 3px 0.1px rgba(107, 107, 107, 0.38) !important;
  -moz-box-shadow: 0px 0px 3px 0.1px rgba(107, 107, 107, 0.38) !important;
  box-shadow: 0px 0px 3px 0.1px rgba(107, 107, 107, 0.38) !important;
}
.ui.card > .content > .header {
  color: #393939 !important;
}
